<template>
   <v-container fluid>
    <v-app-bar :color=colorMenu flat app dense height="40px" dark>
      <v-btn icon :to="{ name: complejo}">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>{{tituloHeader}}</v-toolbar-title>
      <v-spacer></v-spacer> 
       <template v-slot:extension>
        <v-tabs dense
          v-model="tab"
          grow
          show-arrows
          center-active
        >
          <v-tab dense class="ma-0 pa-0" 
            v-for="(item,index) in dias"
            :key="index"
            @click="filtroPorTipo(item.valor)"
          >
            {{ item.dia }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
     <v-card>
       <v-btn color="blue" class="my-0" dense small block outlined  @click="nuevoItem()">AGREGAR ACTIVIDAD EN ESTE DIA </v-btn>                  
       <v-card-text >
                     <v-timeline
                     dense    
                  >
                  <v-timeline-item dense 
                    color="green"                    
                    :icon=actividad.icono 
                    v-for="(actividad,index) in datosObtenidosFiltrados" :key="index"                  >
                    <v-card dense elevation="1">
                       <v-card-text dense >
                         <strong v-html=actividad.descripcion></strong>                         
                     
                                  <v-btn class="mx-2"  x-small outlined color="blue" @click="editar(actividad)" >                
                                        <v-icon small>mdi-pencil</v-icon>
                                  </v-btn>
                                  <v-btn class="mx-0"  outlined x-small color="red" @click="borrar(actividad)">                
                                         <v-icon small>mdi-delete</v-icon>
                                  </v-btn>
                         </v-card-text>            
                    </v-card>                     
                  </v-timeline-item>
                     </v-timeline>
       </v-card-text>
     </v-card>
     <v-dialog v-model=dialog v-if=dialog persistent width="600px">                 
                                <v-card>
                                        <v-card-title >
                                            <span v-if="editItem.id">Modificar</span>
                                            <span v-else>Agregar</span>
                                        </v-card-title>
                                        <v-card-text dense>
                                                <v-row>                                                                                           
                                                    <v-col cols="10" sm="10" class="ma-0 pa-1">
                                                         <v-textarea dense autofocus rows="3"  v-model="editItem.descripcion" outlined></v-textarea>
                                                   </v-col>
                                                    <v-col cols="2" sm="2" class="ma-0 pa-1">
                                                          <v-select
                                                                    :items="iconos"
                                                                    v-model="editItem.icono"
                                                                    label="Icono"
                                                                    return-object
                                                                    dense
                                                                  >
                                                                    <template slot="selection" slot-scope="data">                                                                      
                                                                      <v-avatar size=32 dense>
                                                                        <v-icon >{{data.item}}</v-icon>                                                                       
                                                                      </v-avatar>                                                                      
                                                                    </template>
                                                                    <template slot="item" slot-scope="data">                                                                      
                                                                      <v-avatar size=32 dense>
                                                                              <v-icon>{{data.item}}</v-icon>
                                                                       </v-avatar>
                                                                    </template>
                                                         </v-select>                                                                               
                                                    </v-col>                                                                                    
                                                  </v-row>                                      
                                        </v-card-text>
                                        <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="green" dense class="mx-2 pa-4" dark @click="close()">Cancelar</v-btn>
                                                <v-btn color="green" dense class="mx-2 pa-4" dark  @click="grabarItem(editItem)">Guardar Cambio</v-btn>
                                        </v-card-actions>
                                </v-card>
      </v-dialog>
    <v-row>
      <v-col cols=12>
          <v-card dense class="mt-2 mb-4 mx-4 pa-2 caption text-center" elevation-1 :color=colorMenu dark v-show="pie">                      
                                <span v-html="pie"></span>                         
          </v-card>
      </v-col>      
    </v-row>
  </v-container>   
</template>
<script>

import axios from 'axios'
export default {
  name:"actividadeAdmin",
   props: {
          tituloHeader: String,
          idcomplejo:Number,
          complejo: String,
          colorMenu:String
  },
  data() {
    return {
           dialog:false,
           diaElegido:'',
           icono:'',
           iconos:["","mdi-duck","mdi-yoga","mdi-soccer","mdi-emoticon-excited-outline","mdi-lightbulb-on-outline","mdi-tennis","mdi-meditation","mdi-drama-masks","mdi-music","mdi-drag","mdi-account-group","mdi-trophy-variant","mdi-campfire"],
           tab:null,
           tabelegido:0,
           datosObtenidos:[],
           datosObtenidosFiltrados:[],
           editItem: {},
           valorOriginal:{},
           pie:'Todas las actividades SON GRATUITAS y se realizan al aire libre, los torneos tienen inscripción previa!<br>Veni a divertirte y compartir con los cuidados pertinentes a los TIEMPOS DE COVID!',
           dias:[{
                   dia: 'Domingo',
                   valor: 1
                 },
                 { dia: 'Lunes',
                  valor:2},
                  {
                   dia: 'Martes',
                   valor: 3
                 },
                 {
                   dia: 'Miercoles',
                   valor: 4
                 },
                 {
                   dia: 'Jueves',
                   valor: 5
                 },
                 {
                   dia: 'Viernes',
                   valor: 6
                 }
           ]
        }
  },
    created: async function() {                
               await axios.get(process.env.VUE_APP_API +'/complejo/actividades',
                                {params:{
                                  idcomplejo: this.idcomplejo
                                    }
                              })
                          .then((response) => {
                                              this.datosObtenidos= response.data
                                             
                          })
               let dia = new Date().getDay(); 
               if (dia==6){
                 this.filtroPorTipo(1)
               }
               else {
                  this.filtroPorTipo(dia+1) 
               }
  },
  methods:{
           filtroPorTipo(id) { 
                                this.tab=id-1
                                this.datosObtenidosFiltrados = this.datosObtenidos.filter(
                                  dia => dia.dia === id

                                )
                                this.diaElegido=id
                               
                              },
            close(){ 
            this.dialog=false
            },   
            nuevoItem(){
                      this.editItem={}
                      this.dialog=true
                      this.editItem.id=item.id
                      this.editItem.dia=this.diaElegido
                      this.editItem.estado=1
                      this.editItem.idcomplejo=this.idcomplejo      
            },
            editar(item) {   
                          this.dialog=true
                          this.valorOriginal=Object.assign({},item)
                          this.editItem=this.valorOriginal
                          this.editItem.descripcion=this.editItem.descripcion.replace(/(<br>)/g, '\n')
                          },
            borrar(item) {
                            const index = this.datosObtenidosFiltrados.indexOf(item)
                            const indexprincipal = this.datosObtenidos.indexOf(item)

                            let respuesta= confirm('Esta seguro de borrar: (' +  item.descripcion + ') ?')
                          if (respuesta) {
                              axios.delete(process.env.VUE_APP_API +'/complejo/itemactividad/',  {
                                                    params: {   
                                                            id: item.id                                                                                  
                                                            }
                                                    })
                                                    .then((response) => {
                                                        if (response.status == 200){  
                                                            this.dialog=false                                      
                                                        }
                                                        else {
                                                          alert("Error en borrar item: " + item.id)
                                                          console.log( response)
                                                        }
                                                    })
                                                    .catch(function(err) {
                                                                        alert(err)
                                                      }) 

                            this.datosObtenidosFiltrados.splice(index, 1)
                            this.datosObtenidos.splice(indexprincipal,1)
                            }
                            else  {
                              } 
                        },
            grabarItem(item){
              item.descripcion=  item.descripcion.replace(/(\n)/g, '<br>')     
              let resultado=''
              if (!item.id){ //si es nuevo                                   
                        axios.post(process.env.VUE_APP_API +'/complejo/itemactividad',  {
                                        params: {            
                                                Descripcion: item.descripcion,
                                                IdComplejo: this.idcomplejo,
                                                Dia: this.diaElegido,
                                                Icono: item.icono,                                                
                                                Estado: '1'                                                                                 
                                                }
                                        })
                                        .then((response) => {                                          
                                            if (response.status == 200){                                           
                                                item.id=response.data.respuesta
                                                this.datosObtenidosFiltrados.push(item)
                                                this.datosObtenidos.push(item) 
                                                this.dialog=false
                                               this.datosObtenidosFiltrados.sort(((a, b) => a.descripcion.toLowerCase().localeCompare(b.descripcion.toLowerCase())))
                                            }
                                            else {
                                              alert("Error en Agregar item actividad")
                                            }
                                        })
                                        .catch(function(err) {
                                                            alert("Error" + err)
                                          }) 
                                          return resultado
              }else{ // ACTUALIZAR ACTIVIDAD                      
                      axios.put(process.env.VUE_APP_API +'/complejo/itemactividad/',  {
                                        params: {   
                                                Id: item.id,
                                                Descripcion: item.descripcion,
                                                IdComplejo: item.idcomplejo,
                                                Dia: item.dia,
                                                Icono: item.icono,                                                
                                                Estado: item.estado                                                                                                                               
                                                }
                                        })
                                        .then((response) => {
                                            if (response.status == 200){ 
                                              let indice= this.datosObtenidosFiltrados.findIndex(contenido=> contenido.id == response.data.respuesta) 
                                                Object.assign(this.datosObtenidosFiltrados[indice], item)
                                                this.datosObtenidosFiltrados.sort(((a, b) => a.descripcion.toLowerCase().localeCompare(b.descripcion.toLowerCase())))
                                                this.dialog=false                                              
                                            }
                                            else {
                                              alert("Error en Actualizar item actividad")
                                              console.log( response)
                                            }
                                        })
                                        .catch(function(err) {
                                                            alert(err)
                                          })                       
                        }        
            }
          }    
 }
</script>